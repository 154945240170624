/**
 * IDE 에선 문제없이 참조되나, 컴파일 시 참조할 수 없다는
 * 오류가 발생하여 polyfill 을 추가하여 해결.
 *
 * @see FileSystemHandle
 */

export {};

declare global {
  interface FileSystemGetFileOptions {
    create?: boolean;
  }

  interface FileSystemGetDirectoryOptions {
    create?: boolean;
  }

  interface FileSystemRemoveOptions {
    recursive?: boolean;
  }

  interface FileSystemHandle {
    kind: 'file' | 'directory';
    name: string;
    isSameEntry(other: FileSystemHandle): Promise<boolean>;
    queryPermission(
      descriptor?: 'read' | 'readwrite'
    ): Promise<PermissionState>;
    requestPermission(
      descriptor?: 'read' | 'readwrite'
    ): Promise<PermissionState>;
  }

  const FileSystemHandle: {
    prototype: FileSystemHandle;
    new (): FileSystemHandle;
  };

  interface FileSystemFileHandle extends FileSystemHandle {
    kind: 'file';
    getFile(): Promise<File>;
  }

  const FileSystemFileHandle: {
    prototype: FileSystemFileHandle;
    new (): FileSystemFileHandle;
  };

  interface FileSystemDirectoryHandle
    extends FileSystemHandle,
      AsyncIterable<FileSystemFileHandle | FileSystemDirectoryHandle> {
    kind: 'directory';
    getFileHandle(
      name: string,
      options?: FileSystemGetFileOptions
    ): Promise<FileSystemFileHandle>;
    getDirectoryHandle(
      name: string,
      options?: FileSystemGetDirectoryOptions
    ): Promise<FileSystemDirectoryHandle>;
    entries(): AsyncIterable<FileSystemFileHandle | FileSystemDirectoryHandle>;
    values(): AsyncIterable<FileSystemFileHandle | FileSystemDirectoryHandle>;
    resolve(possibleDescendant: FileSystemHandle): Promise<string[] | null>;
    removeEntry(name: string, options?: FileSystemRemoveOptions): Promise<void>;
  }

  const FileSystemDirectoryHandle: {
    prototype: FileSystemDirectoryHandle;
    new (): FileSystemDirectoryHandle;
  };

  interface Window {
    showDirectoryPicker(): Promise<FileSystemDirectoryHandle>;
  }
}
